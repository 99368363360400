/* Apply box-sizing globally */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  background-color: #f0f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.App-header {
  text-align: center;
  color: #ff69b4;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

.chat-container {
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  transition: height 0.3s ease;
}

.chat-window {
  width: 100%; /* Make the chat window responsive */
  max-width: 100%;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 20px;
  max-width: 70%;
  position: relative;
  clear: both;
  font-size: 1rem;
}

.text-bubble {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 15px;
  max-width: 70%;
}

.unicorn-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

.message.user {
  background-color: #e0f7fa;
  text-align: right;
  float: right;
  border-bottom-right-radius: 0;
}

.message.system {
  background-color: #f3e5f5;
  text-align: left;
  float: left;
  border-bottom-left-radius: 0;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  outline: none;
  margin-right: 5px;
}

button {
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #ff85c1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.typing {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #f3e5f5;
  animation: typing 1s infinite steps(3);
}

@keyframes typing {
  0%,
  20% {
    box-shadow: 0.5em 0 0 0 #f3e5f5, 1em 0 0 0 #f3e5f5;
  }
  40% {
    box-shadow: 0 0 0 0 #f3e5f5, 0.5em 0 0 0 #f3e5f5, 1em 0 0 0 #f3e5f5;
  }
  60% {
    box-shadow: 0 0 0 0 #f3e5f5, 0 0 0 0 #f3e5f5, 0.5em 0 0 0 #f3e5f5;
  }
  80%,
  100% {
    box-shadow: 0 0 0 0 #f3e5f5, 0 0 0 0 #f3e5f5, 0 0 0 0 #f3e5f5;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .chat-window {
    width: 100%; /* Ensure no horizontal overflow */
    margin: 0 auto;
    padding: 10px;
    font-size: 20px;
  }

  .message {
    font-size: 1.1rem;
    max-width: 90%; /* Make chat bubbles wider on mobile */
  }

  input[type="text"] {
    padding: 8px;
    font-size: 1rem;
  }

  button {
    padding: 15px 20px;
    font-size: 0.9rem;
  }

  .confetti-button {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    padding: 8px;
  }

  .send-button {
    font-size: 18px;
    padding: 10px 20px;
  }

  .chat-message {
    font-size: 18px;
  }
}

/* SettingsMenu Styles */
.settings-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.hamburger-icon {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  padding: 0;
  margin: 0;
}

.hamburger-icon div {
  width: 100%;
  height: 3px;
  background-color: #ff69b4;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

.hamburger-icon:hover div {
  background-color: #f53293;
}

.menu {
  position: absolute;
  right: 0;
  top: 30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.menu button {
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  color: #ff69b4;
}

.menu button:hover {
  background-color: #f0f0f0;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.modal-content h2 {
  color: #ff69b4;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.modal-content input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.modal-content .terms {
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.2;
}

.modal-content button {
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin: 5px;
}

.modal-content button:hover {
  background-color: #ff85c1;
}

.microphone-button:hover {
  background-color: transparent !important;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: pink;
  transition: width 0.1s;
}

/* Default button styles */
.button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .button {
    padding: 15px 30px; /* Increase padding for larger buttons */
    font-size: 18px;    /* Increase font size for better readability */
  }

  .audio-controls button {
    padding: 15px 30px;
    font-size: 18px;
  }
}
