/* src/components/AudioPlayer.css */
.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.audio-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.audio-controls button {
  background-color: #ff69b4; /* Example theme color */
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.audio-controls button:hover {
  background-color: #ff85c0; /* Slightly lighter shade */
}

.audio-controls input[type="range"] {
  width: 100px;
}

